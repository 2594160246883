<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">基础信息管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">设备人员管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      :rules="ruleValidate"
      inline
      class="demo-form-inline"
    >
      <div>
        <el-form-item label="单位" prop="parentId">
          <el-select-tree
            size="mini"
            :props="props"
            :options="companyResult"
            v-model="queryModel.parentId"
            height="200"
          ></el-select-tree
          >&nbsp;
          <el-checkbox v-model="queryModel.subordinate"
            >是否包含下级单位</el-checkbox
          >&nbsp;
        </el-form-item>
        <el-form-item label="关联设备名称" prop="deviceId">
          <el-select
            v-model="queryModel.deviceId"
            popper-class="optionsContent"
            size="mini"
            filterable
            clearable
            remote
            style="width: 220px"
            placeholder="请输入关键词"
            :remote-method="queryDevice"
          >
            <el-option
              v-for="device in deviceResult"
              :key="device.id"
              :label="device.aliasName"
              :value="device.id"
            ></el-option> </el-select
          >&nbsp;
          <el-checkbox v-model="queryModel.notBound"
            >只显示未关联人员</el-checkbox
          >
        </el-form-item>
      </div>
      <div>
        <el-form-item label="编号" prop="personId">
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.personId"
          ></el-input>
        </el-form-item> 
        <el-form-item label="姓名" prop="name">
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.phone"
          ></el-input>
        </el-form-item>

        <el-form-item label="照片是否上传设备" prop="isUploadPhoto">
          <el-select
            v-model="queryModel.isUploadPhoto"
            size="mini"
            filterable
            placeholder="请选择"
            style="width: 120px"
          >
            <el-option value label="全部"></el-option>
            <el-option value="1" label="已上传"></el-option>
            <el-option value="0" label="未上传"></el-option> </el-select
          >&nbsp;
        </el-form-item>
        <el-form-item label="是否同步" prop="isBound">
          <el-select
            v-model="queryModel.isBound"
            size="mini"
            filterable
            placeholder="请选择"
            style="width: 120px"
          >
            <el-option value label="全部"></el-option>
            <el-option value="1" label="已同步"></el-option>
            <el-option value="0" label="未同步"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div>
        <el-form-item
          v-if="position1Show"
          :label="position1"
          prop="position1"
         
        >
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.position1"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="position2Show"
          :label="position2"
          prop="position2"
         
        >
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.position2"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="position3Show"
          :label="position3"
          prop="position3"
         
        >
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.position3"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="position4Show"
          :label="position4"
          prop="position4"
         
        >
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.position4"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="position5Show"
          :label="position5"
          prop="position5"
         
        >
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.position5"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            icon="ios-search"
            @click="handleQuery"
            :loading="loading"
            >查询</el-button
          >&nbsp;
          <el-button
            type="info"
            size="mini"
            style="margin-left: 8px"
            @click="handleReset('queryForm')"
            >重置</el-button
          >&nbsp;
        </el-form-item>
      </div>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-refresh"
        :disabled="multipleSelection.length == 0"
        @click="dataSync"
        >数据同步</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-refresh"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchEnabledFace"
        >开启自动同步</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-refresh"
        :disabled="multipleSelection.length == 0"
        @click="batchBoundDevice = true"
        >关联设备</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-refresh"
        :disabled="multipleSelection.length == 0"
        @click="batchUnBoundDevice = true"
        >解绑设备</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-refresh"
        @click="handleUpdateIsWrite"
        >更新用户图片是否存在</el-button
      >
    </el-row>
    <el-table
      ref="formTable"
      :data="tableData"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="id"
        label="序号"
        width="80"
        fixed="left"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="姓名"
        width="80"
        fixed="left"
      ></el-table-column>
      <el-table-column
        prop="faceImageUrl"
        label="人员照片"
        width="80"
        fixed="left"
      >
        <template slot-scope="{ row }">
          <a :href="row.faceImageUrl" target="_blank">
            <el-avatar
              :size="48"
              shape="circle"
              :src="
                row.faceImageUrl +
                '?x-oss-process=image/resize,m_fill,w_64,h_64'
              "
              :key="row.id"
            ></el-avatar>
          </a>
        </template>
      </el-table-column>
      <el-table-column
        prop="companyName"
        label="单位"
        width="250"
      ></el-table-column>
      <el-table-column
        prop="phone"
        label="手机号"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="idCard"
        label="身份证"
        width="100"
        show-overflow-tooltip
      ></el-table-column>
      
      <el-table-column prop="faceBound" label="照片是否上传设备" width="180">
        <template slot-scope="{ row }">{{
          row.faceBound ? "是" : "否"
        }}</template>
      </el-table-column>
      <el-table-column label="是否自动同步" width="120">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.isSync"
            @change="enabledTo(row, 'sync', row.faceEnabled)"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :disabled="disabled"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="接收微信通知">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.wechatNoticeEnabled"
            @change="enabledTo(row, 'wechatNotice', '')"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :disabled="disabled"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        prop="openId"
        label="微信openid"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" width="300" fixed="right">
        <template slot-scope="{ row }">
          <el-row>
            <el-col>
              <el-link
                type="primary"
                :underline="false"
                @click="bindDevice(row)"
                >关联设备</el-link
              >-
              <el-link
                type="primary"
                :underline="false"
                @click="uploadData(row)"
                >数据同步</el-link
              >-
              <el-link type="primary" :underline="false" @click="showBound(row)"
                >已关联设备</el-link
              >-
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <personDeviceRelation-list
      v-if="showDeviceModal"
      :personId="personId"
      @close="onDetailModalClose"
    ></personDeviceRelation-list>
    <personDeviceRelation-BoundList
      v-if="showBoundModal"
      :personId="personId"
      @close="onDetailModalClose"
    ></personDeviceRelation-BoundList>
    <!-- 批量关联设备 -->
    <el-dialog
      title="关联设备"
      :visible.sync="batchBoundDevice"
      :modal-append-to-body="false"
      style="text-align: left"
      :close-on-click-modal="false"
      element-loading-text="正在操作,请稍等"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-form label-width="150px" v-loading="boundDeviceDisabled">
        <el-form-item label="是否立即同步">
          <el-select
            v-model="isSync"
            filterable
            placeholder="请选择"
            style="width: 120px"
            @change="changeSync"
          >
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备">
          <el-select v-model="boundDeviceId" filterable placeholder="请选择">
            <el-option
              v-for="devices in deviceResult"
              :key="devices.id"
              :label="devices.aliasName"
              :value="devices.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeBatchBoundDevice">关 闭</el-button>
        <el-button type="primary" @click="handleBatchBoundDevice"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 批量关联设备end -->
    <!-- 批量解绑设备 -->
    <el-dialog
      title="解绑设备"
      :visible.sync="batchUnBoundDevice"
      :modal-append-to-body="false"
      style="text-align: left"
      :close-on-click-modal="false"
    >
      <el-form label-width="150px" v-loading="boundDeviceDisabled">
        <el-form-item label="设备">
          <el-select v-model="boundUnDeviceId" filterable placeholder="请选择">
            <el-option
              v-for="devices in deviceResult"
              :key="devices.id"
              :label="devices.aliasName"
              :value="devices.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeBatchUnBoundDevice">关 闭</el-button>
        <el-button type="primary" @click="handleBatchUnBoundDevice"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 批量解绑设备end -->
  </div>
</template>
<script>
import Constant from "@/constant";

import personInfoApi from "@/api/base/personInfo";
import companyInfoApi from "@/api/base/companyInfo";
import companyPositionApi from "@/api/base/companyPosition";
import deviceInfoApi from "@/api/base/deviceInfo";
import personDeviceRelationApi from "@/api/base/personDeviceRelation";
import SelectTree from "@/components/SelectTree";
import PersonDeviceRelationList from "../base/personDeviceRelation-list";
import PersonDeviceRelationBoundList from "../base/personDeviceRelation-BoundList";
import pageUtil from "@/utils/page";
import { getToken } from "@/utils/auth"; // get token from cookie
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "BaseDevicePersonList",
  data() {
    var self = this;

    return {
      ruleValidate: {
        deviceId: [{ required: true, message: "不能为空", trigger: "blur" }],
      },
      queryModel: {
        personId:"",
        companyCode: "",
        parentId: "",
        name: "",
        phone: "",
        idCard: "",
        isBound: "",
        isUploadPhoto: "",
        subordinate: false,
        position1: "",
        position2: "",
        position3: "",
        position4: "",
        position5: "",
        deviceId: "",
        notBound: false,
        popedom: "1",
        status: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 30,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [30, 100, 500],
      multipleSelection: [],
      showModal: false,
      showDeviceModal: false,
      showBoundModal: false,
      batchBoundDevice: false,
      batchUnBoundDevice: false,
      modalTitle: "",
      businessKey: "",
      companyResult: [],
      uploadCompanyId: "",
      batchImportVisible: false,
      batchImportFileList: [],
      disabled: false,
      position1: "",
      position2: "",
      position3: "",
      position4: "",
      position5: "",
      position1Show: false,
      position2Show: false,
      position3Show: false,
      position4Show: false,
      position5Show: false,
      personId: "",
      deviceResult: [],
      boundDeviceId: "",
      boundUnDeviceId: "",
      xlsLoading: false,
      tableHeight: 0,
      boundDeviceDisabled: false,
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
      isSync: "2",
      batchReturnStudent: false,
      returnMessage: "",
    };
  },
  watch: {
    "queryModel.parentId": function (newVal, oldVal) {
      this.queryDevice("");
    },
  },
  created() {
    var self = this;

    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.companyResult = jsonData.data;
        }
      }
    });

    companyPositionApi.detailForCompany().then(function (response) {
      var jsonData = response.data.data;
      if (jsonData.position1Name != null && jsonData.position1Name != "") {
        self.position1 = jsonData.position1Name;
        self.position1Show = true;
      }
      if (jsonData.position2Name != null && jsonData.position2Name != "") {
        self.position2 = jsonData.position2Name;
        self.position2Show = true;
      }
      if (jsonData.position3Name != null && jsonData.position3Name != "") {
        self.position3 = jsonData.position3Name;
        self.position3Show = true;
      }
      if (jsonData.position4Name != null && jsonData.position4Name != "") {
        self.position4 = jsonData.position4Name;
        self.position4Show = true;
      }
      if (jsonData.position5Name != null && jsonData.position5Name != "") {
        self.position5 = jsonData.position5Name;
        self.position5Show = true;
      }
    });

    this.queryDevice("");
    this.loadTree();
  },
  methods: {
    queryDevice(aliasName) {
      var self = this;

      var formData = new FormData();
      formData.append("aliasName", aliasName);
      formData.append("companyId", self.queryModel.parentId);
      formData.append("limit", 19);

      deviceInfoApi.query(formData).then(function (response) {
        var jsonData = response.data;
        if (jsonData.result) {
          if (jsonData.data != null && jsonData.data != "") {
            self.deviceResult = jsonData.data;
          }
        }
      });
    },
    loadTree() {
      var formData = new FormData();

      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      console.log(tree);

      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    handleQuery() {
      var self = this;

      this.$refs["queryForm"].validate((valid) => {
        if (valid) {
          self.changePage(1);
        }
      });
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      if (self.queryModel.parentId == null) {
        self.queryModel.parentId = "";
      }
      
      formData.append("personId", self.queryModel.personId);
      formData.append("companyCode", self.queryModel.companyCode);
      formData.append("parentId", self.queryModel.parentId);

      formData.append("subordinate", self.queryModel.subordinate);

      formData.append("name", self.queryModel.name);
      formData.append("phone", self.queryModel.phone);
      formData.append("idCard", self.queryModel.idCard);
      formData.append("isBound", self.queryModel.isBound);
      formData.append("isUploadPhoto", self.queryModel.isUploadPhoto);

      formData.append("position1", self.queryModel.position1);
      formData.append("position2", self.queryModel.position2);
      formData.append("position3", self.queryModel.position3);
      formData.append("position4", self.queryModel.position4);
      formData.append("position5", self.queryModel.position5);

      formData.append("deviceId", self.queryModel.deviceId);
      formData.append("notBound", self.queryModel.notBound);
      formData.append("popedom", self.queryModel.popedom);
      formData.append("status", self.queryModel.status);
      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      personDeviceRelationApi
        .devicePersonList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;

          //45为分页栏的高度
          //页面高度-列表上面的高度-分页栏高度
          self.tableHeight = pageUtil.autoAdjustHeight(
            self.$refs.formTable.$el
          );
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.changePage(this.pageIndex);
    },
    sortChange(data) {
      this.field = data.column.field;
      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    uploadData(record) {
      //同步人脸数据
      var self = this;
      self.loading = true;
      var uploadSync = new Array(0);
      uploadSync.push(record.id);

      var formData = new FormData();
      formData.append("personId", uploadSync);
      formData.append("deviceId", self.queryModel.deviceId);

      personDeviceRelationApi
        .devicePersonSync(formData)
        .then(function (response) {
          var jsonData = response.data;
          self.loading = false;
          if (jsonData.result) {
            if (jsonData.data) {
              self.changePage(self.pageIndex);
              self.$message({
                type: "success",
                message: "同步成功!",
              });
            } else {
              if (jsonData.message != null) {
                //下载有错误信息提示的报表
                //window.open(response.data);
                self.$message({
                  showClose: true,
                  dangerouslyUseHTMLString: true,
                  message:
                    "错误" +
                    `,<a href="${jsonData.message}" target="_blank">点击下载错误报表</a>&nbsp;`,
                  duration: 30000,
                });
              }
            }
          } else {
            self.$message({
              type: "warning",
              message: jsonData.message,
            });
          }
        });
    },
    dataSync() {
      //批量同步人脸
      var self = this;
      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认同步选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          self.loading = true;
          var formData = new FormData();
          formData.append("personIds", idList);
          formData.append("deviceId", self.queryModel.deviceId);

          personDeviceRelationApi
            .devicePersonsSync(formData)
            .then(function (response) {
              var jsonData = response.data;
              self.loading = false;
              if (jsonData.result) {
                if (jsonData.data) {
                  self.changePage(self.pageIndex);
                  self.$message({
                    type: "success",
                    message: "同步成功!",
                  });
                } else {
                  if (jsonData.message != null) {
                    //下载有错误信息提示的报表
                    //window.open(response.data);
                    self.$message({
                      showClose: true,
                      dangerouslyUseHTMLString: true,
                      message:
                        "错误" +
                        `,<a href="${jsonData.message}" target="_blank">点击下载错误报表</a>&nbsp;`,
                      duration: 30000,
                    });
                  }
                }
              } else {
                self.$message({
                  type: "warning",
                  message: jsonData.message,
                });
              }
            });
        })
        .catch(() => {
          self.loading = false;
        });
    },
    bindDevice(record) {
      //关联设备
      this.personId = record.id;
      this.showDeviceModal = true;
    },
    showBound(record) {
      //展示关联设备
      //人员信息ID
      this.personId = record.id;
      this.showBoundModal = true;
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;
      this.showDeviceModal = false;
      this.showBoundModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    handleBatchEnabledFace() {
      //开启自动同步
      var self = this;
      self.loading = true;

      let boundDeviceId = self.boundDeviceId;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });
      var formData = new FormData();

      formData.append("ids", idList);

      personInfoApi.enabledSyncList(idList).then(function (response) {
        var jsonData = response.data;
        self.loading = false;
        if (jsonData.result) {
          self.changePage(self.pageIndex);
          self.$message({
            type: "success",
            message: "操作成功!",
          });
        } else {
          self.$message({
            type: "warning",
            message: jsonData.message,
          });
        }
      });
    },
    handleBatchBoundDevice() {
      //批量人脸
      var self = this;
      //self.loading = true;
      self.boundDeviceDisabled = true;

      let boundDeviceId = self.boundDeviceId;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });
      var formData = new FormData();

      formData.append("persons", idList);
      formData.append("deviceId", boundDeviceId);
      formData.append("isSync", self.isSync);
      personDeviceRelationApi
        .batchBindPerson(formData)
        .then(function (response) {
          var jsonData = response.data;
          //self.loading = false;
          self.boundDeviceDisabled = false;
          if (jsonData.result) {
            self.changePage(self.pageIndex);
            self.batchBoundDevice = false;
            self.boundDeviceId = "";
            self.$message({
              type: "success",
              message: "关联成功!",
            });
          } else {
            self.$message({
              showClose: true,
              dangerouslyUseHTMLString: true,
              message: `有部分人员关联失败,<a href="${jsonData.message}" target="_blank">点击下载错误信息报表</a>&nbsp;`,
              duration: 30000,
            });
          }
        });
    },
    handleBatchUnBoundDevice() {
      //批量人脸
      var self = this;
      //self.loading = true;
      self.boundDeviceDisabled = true;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });
      var formData = new FormData();

      formData.append("persons", idList);
      formData.append("deviceId", self.boundUnDeviceId);
      personDeviceRelationApi
        .batchUnBindPerson(formData)
        .then(function (response) {
          var jsonData = response.data;
          //self.loading = false;
          self.boundDeviceDisabled = false;
          if (jsonData.result) {
            self.changePage(self.pageIndex);
            self.batchBoundDevice = false;
            self.boundDeviceId = "";
            self.$message({
              type: "success",
              message: "解除关联成功!",
            });
          }
        });
    },
    closeBatchBoundDevice() {
      var self = this;
      self.batchBoundDevice = false;
      self.boundDeviceId = "";
    },
    closeBatchUnBoundDevice() {
      var self = this;
      self.batchUnBoundDevice = false;
      self.boundUnDeviceId = "";
    },
    enabledTo(row, type, value) {
      var self = this;

      if (type == "wechatNotice") {
        var formData = new FormData();
        formData.append("id", row.id);

        personInfoApi.enabledWechatNotice(formData);
      } else {
        this.$confirm("该操作会对设备上的信息进行操作,请确认？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            //self.loading = true;
            if (!self.disabled) {
              var formData = new FormData();
              formData.append("id", row.id);
              if ("face" == type) {
                personInfoApi.enabledFace(formData);
              } else if ("card" == type) {
                personInfoApi.enabledCard(formData);
              } else if ("app" == type) {
                personInfoApi.enabledApp(formData);
              } else if ("guest" == type) {
                personInfoApi.enabledGuest(formData);
              } else if ("wechatNotice" == type) {
                personInfoApi.enabledWechatNotice(formData);
              } else if ("sync" == type) {
                personInfoApi.enabledSync(formData);
              }

              //关闭开关,是自动同步到服务器
              //打开开关需要手动同步服务器
              // if (!value) {
              //   //如果是打开开关,则修改状态
              //   row.faceBound = false;
              // }

              self.disabled = true;

              setTimeout(function () {
                self.disabled = false;
                //self.loading = false;
              }, 1000); //一秒内不能重复点击
            } else {
              self.$message({
                type: "success",
                message: "操作过快!",
              });
            }
          })
          .catch(() => {
            // self.loading = false;
            if (row.faceEnabled) {
              row.faceEnabled = false;
            } else {
              row.faceEnabled = true;
            }
          });
      }
    },
    changeSync(value) {
      var self = this;
      self.isSync = value;
    },
    handleUpdateIsWrite() {
      var self = this;
      this.$refs["queryForm"].validate((valid) => {
        if (valid) {
          //self.changePage(1);
          self.loading = true;
          var formData = new FormData();
          if (self.queryModel.parentId == null) {
            self.queryModel.parentId = "";
          }
          formData.append("companyCode", self.queryModel.companyCode);
          formData.append("parentId", self.queryModel.parentId);

          formData.append("subordinate", self.queryModel.subordinate);

          formData.append("name", self.queryModel.name);
          formData.append("phone", self.queryModel.phone);
          formData.append("idCard", self.queryModel.idCard);
          formData.append("isBound", self.queryModel.isBound);
          formData.append("isUploadPhoto", self.queryModel.isUploadPhoto);

          formData.append("position1", self.queryModel.position1);
          formData.append("position2", self.queryModel.position2);
          formData.append("position3", self.queryModel.position3);
          formData.append("position4", self.queryModel.position4);
          formData.append("position5", self.queryModel.position5);

          formData.append("deviceId", self.queryModel.deviceId);
          formData.append("notBound", self.queryModel.notBound);
          formData.append("popedom", self.queryModel.popedom);
          formData.append("status", self.queryModel.status);

          personDeviceRelationApi
            .updateIsWrite(formData)
            .then(function (response) {
              var jsonData = response.data;
              self.loading = false;
              if (jsonData.result) {
                self.changePage(self.pageIndex);
                self.$message({
                  type: "success",
                  message: "更新成功!",
                });
              }
            });
        }
      });
    },
  },
  async mounted() {
    var self = this;
    this.queryDevice("");
  },
  components: {
    "el-select-tree": SelectTree,
    "personDeviceRelation-list": PersonDeviceRelationList,
    "personDeviceRelation-BoundList": PersonDeviceRelationBoundList,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}

.el-scrollbar__wrap {
  overflow: scroll;
  height: 70%;
}
</style>
